module.exports = {
    MOBILE: {
        ID: 'mobile',
        MIN_SIZE_PX: 0,
        MAX_SIZE_PX: 767
    },
    TABLET: {
        ID: 'tablet',
        MIN_SIZE_PX: 768,
        MAX_SIZE_PX: 1023
    },
    DESKTOP: {
        ID: 'desktop',
        MIN_SIZE_PX: 1024
    }
};
