'use strict';

// Utility Includes
var helpers = {
    carousel: require('./utilities/carousel')
};

// Document ready functions
$(document).ready(function () {
    helpers.carousel.initCarousel(
        '.experience-commerce_layouts-carousel .carousel, .experience-einstein-einsteinCarousel .carousel, .experience-einstein-einsteinCarouselCategory .carousel, .experience-einstein-einsteinCarouselProduct .carousel'
    );

    // Remove class that forces all slides to show. This is required to ensure that lazyload works on this kind of carousels
    setTimeout(function () {
        $('.carousel-item.comm-force-display').removeClass('comm-force-display');
    }, 200);
});
