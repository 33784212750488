'use strict';

// Module imports
var debounce = require('lodash/debounce');

// Utility imports
var viewportHelpers = require('./viewport');

// Constant includes
var BREAKPOINT = require('../constants/breakpoints');

// Constants
var SELECTORS = {
    CAROUSEL: {
        CLASS: '.carousel',
        DATA_SLIDE_TO: 'data-slide-to',
        DATA_AUTO_PLAY_TIME: 'data-autoplaytime',
        INDICATOR: '.pd-carousel-indicators li',
        ACTIVE_INDICATOR: '.active .pd-carousel-indicators-progress-bar, .active .carousel-indicators-progress-bar'
    },
    STATE: {
        HOVER: ':hover'
    }
};
var TIMEOUTS = {};

/**
 * Sets event listener for click on indicators
 * @param {JQuery<HTMLElement>} $element - the carousel in context
 */
function setIndicatorsClick($element) {
    var slideTo = $element.attr(SELECTORS.CAROUSEL.DATA_SLIDE_TO);
    $element.closest(SELECTORS.CAROUSEL.CLASS).carousel(parseInt(slideTo, 10));

    return;
}

/**
 * Fills indicators on autoplay mode
 * @param {JQuery<HTMLElement>} $element - the current carousel that is being used
 */
function fillIndicators($element) {
    // Constants
    const ON_LOOP_TIME = (parseInt($element.attr(SELECTORS.CAROUSEL.DATA_AUTO_PLAY_TIME), 10) * 1000) / 100;
    const CAROUSEL_ID = $element.attr('id');

    // Variables
    var i = 0;

    // 1 - Reset Interval
    if (CAROUSEL_ID in TIMEOUTS) {
        clearInterval(TIMEOUTS[CAROUSEL_ID]);
    }

    // 2 - Set Interval if Autoplay value is declared
    if ($element.attr(SELECTORS.CAROUSEL.DATA_AUTO_PLAY_TIME)) {
        TIMEOUTS[CAROUSEL_ID] = setInterval(function () {
            // 2.1 - increment active indicator value
            if (!$element.is(SELECTORS.STATE.HOVER)) {
                i++;
            }

            // 2.2 - set inline style to fill active indicator
            $element.find(SELECTORS.CAROUSEL.ACTIVE_INDICATOR).css('width', i + '%');

            // 2.3 - reset counter if it reaches 100%
            if (i === 100) {
                i = 0;
                $element.carousel('next');
            }
        }, ON_LOOP_TIME);
    }

    return;
}

/**
 * Get display information related to screen size
 * @param {JQuery<HTMLElement>} element - the current carousel that is being used
 * @returns {Object} an object with display information
 * @override app_storefront_base
 */
function screenSize(element) {
    var result = {
        itemsToDisplay: null,
        sufficientSlides: true
    };
    var extraSmallDisplay = element.data('xs');
    var smallDisplay = element.data('sm');
    var mediumDisplay = element.data('md');
    var numberOfSlides = element.data('number-of-slides');

    switch (viewportHelpers.getViewportSize()) {
        case BREAKPOINT.MOBILE.ID:
            result.itemsToDisplay = extraSmallDisplay;
            break;
        case BREAKPOINT.TABLET.ID:
            result.itemsToDisplay = smallDisplay;
            break;
        case BREAKPOINT.DESKTOP.ID:
            result.itemsToDisplay = mediumDisplay;
            break;
        default:
            break;
    }

    if (result.itemsToDisplay && numberOfSlides <= result.itemsToDisplay) {
        result.sufficientSlides = false;
    }

    return result;
}

/**
 * Makes the next element to be displayed next unreachable for screen readers and keyboard nav
 * @param {JQuery<HTMLElement>} element - the current carousel that is being used
 * @override app_storefront_base
 */
function hiddenSlides(element) {
    var carousel;

    if (element) {
        carousel = $(element);
    }

    if (carousel === undefined || carousel === null || carousel.length === 0) {
        return;
    }

    var screenSizeInfo = screenSize(carousel);

    var lastDisplayedElement;
    var elementToBeDisplayed;

    switch (screenSizeInfo.itemsToDisplay) {
        case 2:
            lastDisplayedElement = carousel.find('.active.carousel-item + .carousel-item');
            elementToBeDisplayed = carousel.find('.active.carousel-item + .carousel-item + .carousel-item');
            break;
        case 3:
            lastDisplayedElement = carousel.find('.active.carousel-item + .carousel-item + .carousel-item');
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        case 4:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        case 6:
            lastDisplayedElement = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            elementToBeDisplayed = carousel.find(
                '.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item'
            );
            break;
        default:
            break;
    }

    carousel.find('.active.carousel-item').removeAttr('tabindex').removeAttr('aria-hidden');
    carousel
        .find('.active.carousel-item')
        .find('a, button, details, input, textarea, select')
        .removeAttr('tabindex')
        .removeAttr('aria-hidden');

    if (lastDisplayedElement) {
        lastDisplayedElement.removeAttr('tabindex').removeAttr('aria-hidden');
        lastDisplayedElement
            .find('a, button, details, input, textarea, select')
            .removeAttr('tabindex')
            .removeAttr('aria-hidden');
    }

    if (elementToBeDisplayed) {
        elementToBeDisplayed.attr('tabindex', -1).attr('aria-hidden', true);
        elementToBeDisplayed
            .find('a, button, details, input, textarea, select')
            .attr('tabindex', -1)
            .attr('aria-hidden', true);
    }
}

/**
 * Initialize all carousel event listeners:
 *  1. Indicators:
 *      1.1. Click;
 *      1.2. Progress animation;
 *  2. Slide animation;
 * @param {string} carouselSelector - selector of the carousel container
 */
function initCarousel(carouselSelector) {
    // 1 - on load calls
    $(carouselSelector).each(function () {
        fillIndicators($(this));
    });

    hiddenSlides(carouselSelector);

    // 2 - Set Slide visibility on resize and carousel set up
    $(window).on(
        'resize',
        debounce(function () {
            hiddenSlides(carouselSelector);
        }, 500)
    );

    $('body').on('carousel:setup', function () {
        hiddenSlides(carouselSelector);
    });

    // 3 - Set touch listeners
    $(carouselSelector).on('touchstart', function (touchStartEvent) {
        var screenSizeInfo = screenSize($(this));

        if (screenSizeInfo.sufficientSlides) {
            var xClick = touchStartEvent.originalEvent.touches[0].pageX;
            $(this).one('touchmove', function (touchMoveEvent) {
                var xMove = touchMoveEvent.originalEvent.touches[0].pageX;
                if (Math.floor(xClick - xMove) > 5) {
                    $(this).carousel('next');
                } else if (Math.floor(xClick - xMove) < -5) {
                    $(this).carousel('prev');
                }
            });
            $(carouselSelector).on('touchend', function () {
                $(this).off('touchmove');
            });
        }
    });

    // 4 - Set on slide listeners
    $(carouselSelector).on('slide.bs.carousel', function (e) {
        var activeCarouselPosition = $(e.relatedTarget).data('position');
        $(this).find('.pd-carousel-indicators .active, .carousel-indicators .active').removeClass('active');
        $(this)
            .find(
                ".pd-carousel-indicators [data-position='" +
                    activeCarouselPosition +
                    "'], .carousel-indicators [data-position='" +
                    activeCarouselPosition +
                    "']"
            )
            .addClass('active');

        var extraSmallDisplay = $(this).data('xs');
        var smallDisplay = $(this).data('sm');
        var mediumDisplay = $(this).data('md');

        var arrayOfSlidesToDisplay = [];

        if (!$(this).hasClass('insufficient-xs-slides')) {
            arrayOfSlidesToDisplay.push(extraSmallDisplay);
        }

        if (!$(this).hasClass('insufficient-sm-slides')) {
            arrayOfSlidesToDisplay.push(smallDisplay);
        }

        if (!$(this).hasClass('insufficient-md-slides')) {
            arrayOfSlidesToDisplay.push(mediumDisplay);
        }

        var itemsToDisplay = Math.max.apply(Math, arrayOfSlidesToDisplay);

        var elementIndex = $(e.relatedTarget).index();
        var numberOfSlides = $('.carousel-item', this).length;
        var carouselInner = $(this).find('.carousel-inner');
        var carouselItem;

        if (elementIndex >= numberOfSlides - (itemsToDisplay - 1)) {
            var it = itemsToDisplay - (numberOfSlides - elementIndex);
            for (var i = 0; i < it; i++) {
                // append slides to end
                if (e.direction === 'left') {
                    carouselItem = $('.carousel-item', this).eq(i);

                    $(carouselItem).appendTo($(carouselInner));
                } else {
                    carouselItem = $('.carousel-item', this).eq(0);

                    $(carouselItem).appendTo($(carouselInner));
                }
            }
        }

        fillIndicators($(this));
    });

    // 5 - After slide animation is over
    $(carouselSelector).on('slid.bs.carousel', function () {
        hiddenSlides($(this));
    });

    // 6 - On indicator click
    var $lis = $(carouselSelector).find('.pd-carousel-indicators li, .carousel-indicators li');

    if ($lis.length) {
        $lis.on('click', function () {
            setIndicatorsClick($(this));
        });
    }
}

module.exports = {
    initCarousel: initCarousel
};
