'use strict';

// Constants imports
var BREAKPOINT = require('../constants/breakpoints');

/**
 * Gets viewport size
 * @returns {string} - viewport id
 */
function getViewportSize() {
    const WIDTH = $(window).width();

    if (WIDTH > BREAKPOINT.MOBILE.MIN_SIZE_PX && WIDTH < BREAKPOINT.MOBILE.MAX_SIZE_PX) {
        return BREAKPOINT.MOBILE.ID;
    } else if (WIDTH > BREAKPOINT.TABLET.MIN_SIZE_PX && WIDTH < BREAKPOINT.TABLET.MAX_SIZE_PX) {
        return BREAKPOINT.TABLET.ID;
    } else if (WIDTH > BREAKPOINT.DESKTOP.MIN_SIZE_PX) {
        return BREAKPOINT.DESKTOP.ID;
    }

    return true;
}

module.exports = {
    getViewportSize: getViewportSize
};
